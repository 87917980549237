import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../api/apiClient";

const RegistrationForm = ({ role }) => {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        password_confirmation: "", // Auto-handled in logic
        name: "",
    });
    const [passwordError, setPasswordError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prev) => ({
            ...prev,
            [name]: value,
            ...(name === "password" && { password_confirmation: value }), // Automatically copy password
        }));

        // Handle real-time password validation
        if (name === "password") {
            setPasswordError(value.length < 6 ? "Password must be at least 6 characters." : null);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Final password validation before submission
        if (formData.password.length < 6) {
            setPasswordError("Password must be at least 6 characters.");
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const payload = {
                user: {
                    email: formData.email,
                    password: formData.password,
                    password_confirmation: formData.password_confirmation,
                    role,
                },
            };

            const response = await apiClient.post("/users", payload);

            // Handle the response and redirect on success
            const { token, user } = response.data;
            localStorage.setItem("authToken", token); // Save the JWT token
            localStorage.setItem("currentUser", JSON.stringify(user)); // Save user details

            // Redirect to Thank You Page
            navigate(`/thank-you/${formData.name}`);
        } catch (err) {
            // Extract error messages from the server's response
            const errorMessages = err.response?.data?.errors || ["An error occurred"];
            setError(errorMessages.join(", "));
        } finally {
            setLoading(false);
        }
    };

    const otherRole = role === "talent" ? "headhunter" : "talent";

    return (
        <form
            onSubmit={handleSubmit}
            className="bg-white p-12 rounded-lg shadow-lg mx-auto max-w-lg"
        >
            <h2 className="text-4xl font-extrabold mb-6 text-center">
                Register as {role === "talent" ? "Talent" : "Headhunter"}
            </h2>
            {error && <div className="text-red-600 text-xl mb-6">{error}</div>}
            <div className="mb-4">
                <label className="block text-gray-700 font-bold text-lg mb-2">{role === "headhunter" ? "Corporate email" : "Email"}</label>
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full p-4 text-lg border-2 rounded-lg focus:ring-4 focus:ring-blue-500"
                    placeholder={role === "headhunter" ? "Enter your corporate email" : "Enter your email"}
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 font-bold text-lg mb-2">Password</label>
                <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    className={`w-full p-4 text-lg border-2 rounded-lg focus:ring-4 ${
                        passwordError ? "border-red-500" : "border-blue-500"
                    }`}
                    placeholder="Enter your password (min. 6 characters)"
                    required
                />
                {passwordError && (
                    <div className="text-red-500 text-sm mt-2">{passwordError}</div>
                )}
            </div>
            <button
                type="submit"
                disabled={loading}
                className={`w-full py-4 text-xl font-bold rounded-lg ${
                    loading
                        ? "bg-gray-400"
                        : "bg-blue-600 text-white hover:bg-blue-700 focus:ring-4 focus:ring-blue-500"
                }`}
            >
                {loading ? "Registering..." : "Register"}
            </button>
            <div className="mt-4 text-center">
                <p className="text-gray-600">
                    {" "}
                    <Link
                        to={`/register/${otherRole}`}
                        className="text-blue-600 font-bold hover:underline"
                    >
                        Register as {otherRole === "talent" ? "Talent" : "Headhunter"}
                    </Link>
                </p>
            </div>
        </form>
    );
};

export default RegistrationForm;
