const Testimonials = () => {
    const testimonials = [
        { name: "Jane Doe", feedback: "Crysto helped me land my dream job!" },
        { name: "John Smith", feedback: "The connections I made on Crysto were invaluable." },
    ];

    return (
        <section className="py-16 bg-gray-100 text-center">
            <h2 className="text-3xl font-bold mb-8">What Our Users Say</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {testimonials.map((testimonial, index) => (
                    <div key={index} className="p-6 bg-white rounded-md shadow-md">
                        <p className="italic text-gray-600">"{testimonial.feedback}"</p>
                        <h4 className="mt-4 font-semibold text-blue-600">{testimonial.name}</h4>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Testimonials;
