import React from "react";
import { useNavigate } from "react-router-dom";

const LoginButton = ({ className }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/login");
    };

    return (
        <button
            onClick={handleClick}
            className={`py-3 px-8 min-w-[200px] rounded-md font-semibold text-lg ${className}`}
        >
            Login
        </button>
    );
};

export default LoginButton;
