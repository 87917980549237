import { Helmet, HelmetProvider } from "react-helmet-async";
import { Outlet } from "react-router-dom";
import { SpeedInsights } from "@vercel/speed-insights/react";

const RootLayout = () => {
    return (
        <HelmetProvider>
            {/* Set Default Metadata */}
            <Helmet>
                <title>Crysto | Connecting Talent with Opportunities</title>
                <meta
                    name="description"
                    content="Crysto helps connect talented individuals with the best opportunities globally."
                />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta property="og:title" content="Crysto | Connecting Talent with Opportunities" />
                <meta property="og:description" content="Connecting Talent with Opportunities" />
                {/*<meta property="og:image" content="https://crysto.io/default-og-image.png" />*/}
                <meta property="og:url" content="https://crysto.io/" />
                <link rel="canonical" href="https://crysto.io/" />
            </Helmet>

            {/* Layout */}
            <div className="min-h-screen flex flex-col justify-between bg-gradient-to-r from-blue-600 to-purple-600">
                {/* Header */}
                <header className="p-6 bg-opacity-30 bg-black text-white shadow-md">
                    <h1 className="text-3xl font-bold">Crysto</h1>
                </header>

                {/* Main Content */}
                <main className="flex-grow">
                    {/* Content sections rendered here */}
                    <Outlet />
                </main>

                {/* Footer */}
                <footer className="p-4 bg-opacity-30 bg-black text-gray-400 text-center">
                    © {new Date().getFullYear()} Crysto. All rights reserved.
                </footer>
            </div>
            <SpeedInsights />
        </HelmetProvider>
    );
};

export default RootLayout;
