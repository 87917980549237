import Hero from "../components/Hero";
import Features from "../components/Features";
import HowItWorks from "../components/HowItWorks";
import Testimonials from "../components/Testimonials";
import CTA from "../components/CTA";
import { Helmet } from "react-helmet-async";



const LandingPage = () => {
    return (
        <>
            <Helmet>
                <title>Welcome to Crysto</title>
                <meta name="description" content="Discover global opportunities with Crysto." />
                <meta property="og:title" content="Welcome to Crysto" />
                <meta property="og:description" content="Discover global opportunities with Crysto." />
            </Helmet>


            <div>
                <Hero />
                <Features />
                <HowItWorks />
                <Testimonials />
                <CTA />
            </div>
        </>
    );
};

export default LandingPage;
