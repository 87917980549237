const HowItWorks = () => {
    const steps = [
        { step: "1", title: "Create Your Profile", description: "Sign up and showcase your skills." },
        { step: "2", title: "Find Opportunities", description: "Browse curated job offers tailored to you." },
        { step: "3", title: "Connect & Grow", description: "Engage with headhunters and employers." },
    ];

    return (
        <section className="py-16 bg-white text-center">
            <h2 className="text-3xl font-bold mb-8">How It Works</h2>
            <div className="flex flex-col md:flex-row justify-center gap-8">
                {steps.map((step, index) => (
                    <div
                        key={index}
                        className="flex flex-col items-center md:items-start text-center md:text-left p-6"
                    >
                        <div className="flex items-center justify-center bg-blue-100 text-blue-600 text-5xl font-bold w-16 h-16 rounded-full mb-4">
                            {step.step}
                        </div>
                        <h3 className="text-xl font-semibold">{step.title}</h3>
                        <p className="text-gray-600 mt-2">{step.description}</p>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default HowItWorks;
