import React from "react";
import { useNavigate } from "react-router-dom";

const RegisterButtons = ({ role, className }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/register/${role}`);
    };

    return (
        <button
            onClick={handleClick}
            className={`py-3 px-8 min-w-[200px] rounded-md font-semibold text-lg ${className}`}
        >
            Register as {role === "talent" ? "Talent" : "Headhunter"}
        </button>
    );
};

export default RegisterButtons;
