import React from "react";
import { useParams, useNavigate } from "react-router-dom";

const ThankYouPage = () => {
    const { name } = useParams(); // Extract name from URL
    const navigate = useNavigate();

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-blue-600 to-purple-600 text-white text-center">
            <div className="max-w-lg p-6 bg-white text-blue-600 rounded-lg shadow-lg">
                <h1 className="text-4xl font-extrabold mb-4">Thank You, {name}!</h1>
                <p className="text-lg mb-6">
                    We appreciate your interest in Crysto. We'll let you know as soon as we launch!
                </p>
                <button
                    onClick={() => navigate("/")}
                    className="bg-blue-600 text-white py-2 px-6 rounded-md shadow-md font-semibold hover:bg-blue-700"
                >
                    Back to Home
                </button>
            </div>
        </div>
    );
};

export default ThankYouPage;
