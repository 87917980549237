import React from "react";
import RegisterButtons from "../components/RegisterButtons";


const CTA = () => {
    return (
        <section className="py-16 bg-pattern bg-blue-600 text-white text-center">
            <div className="max-w-screen-lg mx-auto px-4">
                <h2 className="text-3xl font-bold">Ready to Join Crysto?</h2>
                <p className="text-lg mt-4">Start your journey today and unlock global opportunities.</p>
                <div className="flex justify-center gap-4 mt-8">
                    <RegisterButtons
                        role="talent"
                        className="bg-blue-500 text-white hover:bg-blue-600 py-3 px-8 min-w-[200px] text-lg font-semibold rounded-md shadow-md flex items-center justify-center"
                    />
                    <RegisterButtons
                        role="hunter"
                        className="bg-blue-500 text-white hover:bg-blue-600 py-3 px-8 min-w-[200px] text-lg font-semibold rounded-md shadow-md flex items-center justify-center"
                    />
                </div>
            </div>
        </section>
    );
};

export default CTA;
