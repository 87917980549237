import React from "react";
import RegisterButtons from "../components/RegisterButtons";
import LoginButton from "./LoginButton";

const Hero = () => {
    return (
        <section className="bg-pattern bg-blue-600 text-white text-center py-16">
            <div className="container max-w-screen-lg mx-auto px-4">
                <h1 className="text-4xl md:text-6xl font-bold">Welcome to Crysto</h1>
                <p className="text-lg md:text-2xl mt-4">
                    Connecting talent with opportunities worldwide.
                </p>
                <div className="flex justify-center gap-4 mt-8">
                    <RegisterButtons
                        role="talent"
                        className="bg-blue-500 text-white hover:bg-blue-600 py-3 px-8 min-w-[200px] text-lg font-semibold rounded-md shadow-md flex items-center justify-center"
                    />
                    <RegisterButtons
                        role="hunter"
                        className="bg-blue-500 text-white hover:bg-blue-600 py-3 px-8 min-w-[200px] text-lg font-semibold rounded-md shadow-md flex items-center justify-center"
                    />
                    <LoginButton
                        className="bg-blue-500 text-white hover:bg-blue-600 py-3 px-8 min-w-[200px] text-lg font-semibold rounded-md shadow-md flex items-center justify-center"
                    />
                </div>
            </div>
        </section>
    );
};

export default Hero;
