const Features = () => {
    return (
        <section className="bg-gray-100 py-16 text-center">
            <h2 className="text-3xl font-bold mb-8">Why Choose Crysto?</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="card">
                    <h3>Global Talent Pool</h3>
                    <p>Find professionals from all over the world.</p>
                </div>
                <div className="card">
                    <h3>Seamless Connections</h3>
                    <p>Effortlessly connect with headhunters and employers.</p>
                </div>
                <div className="card">
                    <h3>Tailored Matches</h3>
                    <p>Match skills to opportunities with precision.</p>
                </div>
            </div>
        </section>
    );
};

export default Features;
