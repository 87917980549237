import React from 'react';
import { Routes, Route } from 'react-router-dom';
import RootLayout from '../layouts/RootLayout';
import LandingPage from "../pages/LandingPage";
import RegistrationPage from "../pages/RegistrationPage";
import ThankYouPage from "../pages/ThankYouPage";


const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<RootLayout />}>
                {/* Index route */}
                <Route index element={<LandingPage />} />
                <Route path="/register/:role" element={<RegistrationPage />} />
                <Route path="/thank-you/:name" element={<ThankYouPage />} />
            </Route>
        </Routes>
    );
};

export default AppRoutes;
