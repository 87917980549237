import React from "react";
import { useParams } from "react-router-dom";
import RegistrationForm from "../components/RegistrationForm";

const RegistrationPage = () => {
    const { role } = useParams();

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-600 to-purple-600">
            <RegistrationForm role={role} />
        </div>
    );
};

export default RegistrationPage;
